import photo1 from './22a_01.jpg';
import photo2 from './22a_02.jpg';
import photo3 from './22a_03.jpg';
import photo4 from './22a_04.jpg';

const images22a: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
];

export default images22a;
