import photo1 from './25_01.jpg';
import photo2 from './25_02.jpg';
import photo3 from './25_03.jpg';
import photo4 from './25_04.jpg';
import photo5 from './25_05.jpg';
import photo6 from './25_06.jpg';
import photo7 from './25_07.jpg';
import photo8 from './25_08.jpg';
import photo9 from './25_09.jpg';
import photo10 from './25_10.jpg';
import photo11 from './25_11.jpg';
import photo12 from './25_12.jpg';
import photo13 from './25_13.jpg';
import photo14 from './25_14.jpg';
import photo15 from './25_15.jpg';
import photo16 from './25_16.jpg';
import photo17 from './25_17.jpg';
import photo18 from './25_18.jpg';
import photo19 from './25_19.jpg';

const images25: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
  photo13,
  photo14,
  photo15,
  photo16,
  photo17,
  photo18,
  photo19,
];

export default images25;
