import photo1 from './27_01.jpg';
import photo2 from './27_02.jpg';
import photo3 from './27_03.jpg';
import photo4 from './27_04.jpg';
import photo5 from './27_05.jpg';
import photo6 from './27_06.jpg';
import photo7 from './27_07.jpg';
import photo8 from './27_08.jpg';

const images27: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
];

export default images27;
