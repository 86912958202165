import photo1 from './23_01.jpg';
import photo2 from './23_02.jpg';
import photo3 from './23_03.jpg';
import photo4 from './23_04.jpg';
import photo5 from './23_05.jpg';
import photo6 from './23_06.jpg';
import photo7 from './23_07.jpg';

const images23: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
];

export default images23;
