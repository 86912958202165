import photo1 from './14_01.jpg';
import photo2 from './14_02.jpg';
import photo3 from './14_03.jpg';

const images14: string[] = [
  photo1,
  photo2,
  photo3,
];

export default images14;
