import photo1 from './30_01.jpg';
import photo2 from './30_02.jpg';
import photo3 from './30_03.jpg';
import photo4 from './30_04.jpg';
import photo5 from './30_05.jpg';
import photo6 from './30_06.jpg';

const images30: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
];

export default images30;
