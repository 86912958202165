import photo1 from './21a_01.jpg';
import photo2 from './21a_02.jpg';
import photo3 from './21a_03.jpg';

const images21a: string[] = [
  photo1,
  photo2,
  photo3,
];

export default images21a;
