/* eslint-disable max-len */
export const planPageText: string =
  `Время работы администрации с 8:00 до 19:00.
  
  Заезд с 16:00, выезд до 15:00 часов.

  Проживание с домашними питомцами возможно при согласовании с администрацией по телефону.

  Услуга бронирования - безвозвратная.

  Парковка автомобилей бесплатна и возможна только на парковочной зоне.

  Во всех домиках чистое постельное бельё и лицевые полотенца, гель для душа. Для бань предоставляются банные простыни и дрова.

  Дома оборудованы встроенной кухней с холодильником, плитой, микроволновкой, чайником, посудой, моющими средствами. Перед каждым домом своя мангальная зона. Мангалы входят в стоимость аренды домика.
  
  На новый год (с 31 декабря по 2 января): от двух суток +50% к стоимости.`;

export const mapPageText: string =
  `100 км по трассе М5 Екатеринбург - Челябинск, поворот на Касли, Кыштым, 16 км указатель б/о У семи озёр
  
  Адрес: Челябинская бласть, Каслинский район, У семи озёр
  
  Расстояние от Екатерибунга: 119 км
  Расстояние от Челябинска: 125 км
  Координты:  55.943508, 60.786078`;
