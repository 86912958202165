import photo1 from './22_01.jpg';
import photo2 from './22_02.jpg';
import photo3 from './22_03.jpg';
import photo4 from './22_04.jpg';
import photo5 from './22_05.jpg';
import photo6 from './22_06.jpg';
import photo7 from './22_07.jpg';
import photo8 from './22_08.jpg';
import photo9 from './22_09.jpg';
import photo10 from './22_10.jpg';
import photo11 from './22_11.jpg';

const images22: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
];

export default images22;
