import photo1 from './13b_01.jpg';
import photo2 from './13b_02.jpg';
import photo3 from './13b_03.jpg';
import photo4 from './13b_04.jpg';
import photo5 from './13b_05.jpg';
import photo6 from './13b_06.jpg';
import photo7 from './13b_07.jpg';
import photo8 from './13b_08.jpg';
import photo9 from './13b_09.jpg';

const images13b: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
];

export default images13b;
