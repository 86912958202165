import photo1 from './13a_01.jpg';
import photo2 from './13a_02.jpg';
import photo3 from './13a_03.jpg';
import photo4 from './13a_04.jpg';
import photo5 from './13a_05.jpg';
import photo6 from './13a_06.jpg';
import photo7 from './13a_07.jpg';

const images13a: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
];

export default images13a;
