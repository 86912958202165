import photo1 from './24_01.jpg';
import photo2 from './24_02.jpg';
import photo3 from './24_03.jpg';
import photo4 from './24_04.jpg';
import photo5 from './24_05.jpg';
import photo6 from './24_06.jpg';
import photo7 from './24_07.jpg';

const images24: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
];

export default images24;
