import photo1 from './26_01.jpg';
import photo2 from './26_02.jpg';
import photo3 from './26_03.jpg';
import photo4 from './26_04.jpg';
import photo5 from './26_05.jpg';
import photo6 from './26_06.jpg';
import photo7 from './26_07.jpg';
import photo8 from './26_08.jpg';
import photo9 from './26_09.jpg';
import photo10 from './26_10.jpg';
import photo11 from './26_11.jpg';
import photo12 from './26_12.jpg';
import photo13 from './26_13.jpg';

const images26: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
  photo13,
];

export default images26;
