import photo1 from './21_01.jpg';
import photo2 from './21_02.jpg';
import photo3 from './21_03.jpg';
import photo4 from './21_04.jpg';
import photo5 from './21_05.jpg';
import photo6 from './21_06.jpg';
import photo7 from './21_07.jpg';
import photo8 from './21_08.jpg';
import photo9 from './21_09.jpg';
import photo10 from './21_10.jpg';
import photo11 from './21_11.jpg';
import photo12 from './21_12.jpg';
import photo13 from './21_13.jpg';
import photo14 from './21_14.jpg';

const images21: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
  photo13,
  photo14,
];

export default images21;
