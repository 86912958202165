import photo1 from './01.jpg';
import photo2 from './02.jpg';
import photo3 from './03.jpg';
import photo4 from './04.jpg';
import photo5 from './05.jpg';
import photo6 from './06.jpg';
import photo7 from './07.jpg';

interface IMainPageData {
  title: string,
  subtitle: string,
  decription: string,
  image: string,
}

const mainPageData: IMainPageData[] = [
  {
    title: 'Для всех желающих',
    subtitle: 'Прекрасный отдых',
    decription: `База отдыха "У семи озёр" расположена в лесной зоне на берегу озера Большие Касли в 5 км от районного центра город Касли.
      К услугам отдыхающих предлагаются комфортные домики с размещением от 3-х до 30-и человек.
      На базе есть русские бани, сауна, детская площадка, вода из скважины, парковка для автомобилей.`,
    image: photo1,
  },
  {
    title: 'Приятные вечера',
    subtitle: 'Мангальная зона',
    decription: `У каждого домика есть беседка со столом и скамейками.
      В компании друзей можно провести приятный вечер у мангальной зоны.`,
    image: photo2,
  },
  {
    title: 'Хорошая парная',
    subtitle: 'Бани и домики с саунами',
    decription: 'Чтобы загородных отдых был насыщенным и полезным для здоровья, разнообразьте его посещением русской бани на дровах.',
    image: photo3,
  },
  {
    title: 'Активный отдых',
    subtitle: 'Спорт и развлечения',
    decription: `Для активного отдыха на территории базы расположены волейбольная площадка, футбольное поле, теннисный корт и детские качели.
      Проведите время занимаясь спортом и наслаждаясь чистым воздухом лесопарка.`,
    image: photo4,
  },
  {
    title: 'Удобные понтоны',
    subtitle: 'Рыбалка и отдых на озере',
    decription: `Для рыбаков в летнее время выставляются понтоны.
      В зимнее время на озере организуется подлёдный лов.
      Водится рыба: щука, лещ, карась, язь, елец, плотва, окунь, ёрш, корюшка, сиг, пелядь, налим и линь.`,
    image: photo5,
  },
  {
    title: 'Незабываемый праздник',
    subtitle: 'Корпоративы',
    decription: `Наша база – это идеальное место для проведения активных интересных корпоративов.
      Для проведения корпоративного отдыха предлагается гостевой дом №25 с банкетным залом до 50 гостей и дом №21.`,
    image: photo6,
  },
  {
    title: 'Летний отдых',
    subtitle: 'Загородный детский отдых',
    decription: 'База отдыха приглашает к сотрудничеству организаторов для проведения детского летнего отдыха.',
    image: photo7,
  },
];

export default mainPageData;
