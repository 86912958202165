import photo1 from './07_01.jpg';
import photo2 from './07_02.jpg';
import photo3 from './07_03.jpg';
import photo4 from './07_04.jpg';
import photo5 from './07_05.jpg';
import photo6 from './07_06.jpg';
import photo7 from './07_07.jpg';
import photo8 from './07_08.jpg';

const images07: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
];

export default images07;
