import photo1 from './20_01.jpg';
import photo2 from './20_02.jpg';
import photo3 from './20_03.jpg';
import photo4 from './20_04.jpg';
import photo5 from './20_05.jpg';
import photo6 from './20_06.jpg';
import photo7 from './20_07.jpg';
import photo8 from './20_08.jpg';
import photo9 from './20_09.jpg';
import photo10 from './20_10.jpg';
import photo11 from './20_11.jpg';
import photo12 from './20_12.jpg';

const images20: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
];

export default images20;
