import photo1 from './28_01.jpg';
import photo2 from './28_02.jpg';
import photo3 from './28_03.jpg';
import photo4 from './28_04.jpg';
import photo5 from './28_05.jpg';
import photo6 from './28_06.jpg';
import photo7 from './28_07.jpg';
import photo8 from './28_08.jpg';

const images28: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
];

export default images28;
